import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS } from '@pk/powerkioskutils';

import { AppStateService } from '../../core/app-state.service';
import { SecurityService } from '../../security/security.service';
import { User } from '../../shared/models';

@Injectable({
	providedIn: 'root'
})
export class AuthResolver {
	constructor(private securityService: SecurityService,
		private appStateService: AppStateService,
		private router: Router) { }

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		try {
			const res = await this.securityService.getAuthFields();
			if (res.token && res.refreshToken && res.loggedInUser) {
				this.securityService.setAuthFields(res.token, res.refreshToken, res.loggedInUser);
				const user = new User(res.loggedInUser);
				this.securityService.isDirect = false;
				this.appStateService.setReady({ app: true, direct: false, isPopupWindow: route.data.isPopupWindow });
				const isImpersonating = this.securityService.isImpersonating;

				if (await this.checkShouldRedirectPasswordExpired(user, state, isImpersonating)) { return false; }
				if (await this.checkShouldRedirectFailedPayment(user, state, isImpersonating)) { return false; }
				if (await this.checkShouldRedirectInactiveBroker(user, route, isImpersonating)) { return false; }

				if (user.isAdmin || user.isOwner) {
					if (await this.checkShouldRedirectAdmin(route)) { return false; }
				} else if (user.supplierId) {
					if (await this.checkShouldRedirectSupplier(user, route)) { return false; }
				} else {
					if (await this.checkShouldRedirectOthers(route)) { return false; }
				}

				if (route.data.showFeedback) {
					this.appStateService.showFeedback(route.data.showFeedback);
				} else {
					this.appStateService.showFeedback('Other');
				}

				return true;
			}
		} catch (e) {
			// nothing
		}

		await this.returnToLogin(state);
		return false;
	}

	private async returnToLogin(state: RouterStateSnapshot): Promise<void> {
		this.securityService.isDirect = false;
		this.appStateService.setReady({ app: false, direct: false, isPopupWindow: false });
		await this.router.navigate(['/security'], { queryParams: { returnUrl: state.url } });
	}

	private async checkShouldRedirectAdmin(route: ActivatedRouteSnapshot): Promise<boolean> {
		if (route.data.isAdmin || route.data.isOwner) { return false; }

		// admins must impersonate suppliers to view the supplier portal
		if (route.data.isSupplier) {
			await this.router.navigate(['/contract']);
			return true;
		}

		// admins must imperonate brokers to view certain pages that are broker specific
		if (route.data.isBroker) {
			await this.router.navigate(['/contract']);
			return true;
		}

		return false;
	}

	private async checkShouldRedirectSupplier(user: User, route: ActivatedRouteSnapshot): Promise<boolean> {
		if (route.data.isSupplier) { return false; }

		// Suppliers must be explicitly given access to routes
		if (!user || user.roleId === CONSTANTS.userRoles.supplierCommercial.roleId) {
			await this.router.navigate(['/supplier']);
		} else if (user && user.roleId === CONSTANTS.userRoles.supplierResidential.roleId) {
			await this.router.navigate(['/supplier/residential-confirmations']);
		}
		return true;
	}

	private async checkShouldRedirectOthers(route: ActivatedRouteSnapshot): Promise<boolean> {
		if (route.data.isBroker) { return false; }

		// only admins allowed here
		if (route.data.isAdmin || route.data.isOwner) {
			await this.router.navigate(['/contract']);
			return true;
		}

		return false;
	}

	private async checkShouldRedirectPasswordExpired(user: User, state: RouterStateSnapshot, isImpersonating: boolean): Promise<boolean> {
		if (!isImpersonating && user.isPasswordExpired && !state.url.includes('security/edit')) {
			await this.router.navigate(['/security/edit']);
			return true;
		}
		return false;
	}

	private async checkShouldRedirectFailedPayment(user: User, state: RouterStateSnapshot, isImpersonating: boolean): Promise<boolean> {
		if (!isImpersonating && user.agent && user.agent.hasFailedPayment && !state.url.includes('security/edit#manageCreditCard')) {
			if (user.agent.parent) {
				await this.router.navigate(['/security/edit']);
			} else {
				await this.router.navigate(['/security/edit'], { fragment: 'manageCreditCard' });
			}
			return true;
		}
		return false;
	}

	private async checkShouldRedirectInactiveBroker(user: User, route: ActivatedRouteSnapshot, isImpersonating: boolean): Promise<boolean> {
		// can remove hasOwnProperty check after a few months
		if (!isImpersonating && user.agent && user.agent.hasOwnProperty('isActive') && !user.agent.isActive && !route.data.allowInactive) {
			await this.router.navigate(['/security/edit']);
			return true;
		}
		return false;
	}
}
